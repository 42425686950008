@import '../../../../Theme/palette';
@import '../../../../Theme/variables';

.homepage-tags {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .homepage-tag {
    opacity: 0.5;
    cursor: pointer;
    flex: 1;
    text-align: center;
    margin: 0;

    &:hover {
      opacity: 1;
    }
  }

  .active-tag {
    opacity: 1;
  }

  .tag-row {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    justify-content: space-between;
  }
}
