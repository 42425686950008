#roles-page {
  .grid-content-wrapper .filter-row {
    a {
      margin-left: 0;
    }
  }

  .remove-height-full {
    height: 100%;
  }
}
