.edit-field-search-form {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .add-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
