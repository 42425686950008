.add-field-search-form {
  min-width: 600px;

  @media (max-width: 768px) {
    min-width: 400px;
  }

  @media (max-width: 550px) {
    min-width: 100%;
  }

  .add-field-search-form-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;

    .ant-col.ant-form-item-label {
      text-align: left;
      flex: 1;
    }

    .ant-col.ant-form-item-control {
      flex: 3;
    }
  }

  .add-button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
