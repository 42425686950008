@import '~antd/dist/antd.css';
@import './Theme/variables';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-grid-header {
  align-items: center;

  .anticon {
    margin-right: 8px;
  }
}

.grid-content-wrapper {
  .users-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 1.25rem;
  }

  .filter-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;

    a {
      margin-left: auto;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.custom-scrollbar {
  scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: $scrollbar-thumb;
}

.scroll-test::-webkit-scrollbar-thumb {
  background-color: rgb(215, 212, 212) !important;
}

.update-note {
  color: #a5a5a5;
}
