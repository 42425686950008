.version-file-group-card {
  max-width: 330px;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;

  .ant-card-body {
    padding-bottom: 0.25rem;
  }

  .group-name {
    text-align: center;
  }

  .grid-content-wrapper {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 1rem;
  }

  .ant-card-actions {
    border-bottom: 1px solid #f0f0f0;
    border-top: none;
  }
}
