.field-search-item {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-grow: 1;

  .field-search-input {
    flex: 1;
  }

  .field-search-select {
    flex: 3;
  }
}
