.pagination-filter {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  flex-wrap: wrap;

  .ant-tag {
    padding: 2px;
    height: 32px;
    display: flex;
    flex-direction: row;
    gap: 0.1rem;
    margin: 0;
    align-items: center;
  }

  .ant-input {
    width: auto;
  }

  .form-field-container {
    .ant-select {
      min-width: 120px;
    }

    .number-filter {
      max-width: 75px;
    }
  }
}
