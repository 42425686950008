.draggable-wrapper {
  display: inline-block;
  position: relative;
  z-index: 0;
  .drag-icon {
    background-color: rgba(255, 255, 255, 0.55);
    border-radius: 2px;
    padding: 2px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }
}
