.brand-card-container {
  position: relative !important;

  .brand-card {
    .card-second-row {
      justify-content: space-between;
      align-items: center;
      .first-item-text {
        text-align: center;
      }
    }
  }
}

.is-poc {
  position: absolute;
  left: 4px;
  bottom: 4px;
  padding: 1px 0.15rem;
  font-size: 0.65rem;
  line-height: 0.85rem;
}
