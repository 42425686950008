.camera-card {
  padding: 8px -10px 12px 8px !important;
  width: auto;

  .first-item {
    overflow: visible !important;
  }

  .camera-card-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: auto;
    max-height: 200px;
    margin-left: -5px;
    margin-bottom: -15px;
    div {
      display: flex;
      min-width: 105px;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 13px;
        margin-bottom: -10px;
        margin-top: -10px;
      }
    }
  }
}
