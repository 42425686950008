.staticIpForm {
  max-width: 25vw;
  margin: auto;

  .buttonItem {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .submitButton {
    justify-content: end;
    display: flex;
    margin: auto;
  }
  .submitButtonItem {
    display: flex;
    justify-content: end;
  }
}
