@import '../../Theme/palette';
@import '../../Theme/variables';

.card-grid-container {
  $radius: 0.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1.25rem;

  .card-wrapper {
    border: 1px solid transparent;
    border-radius: $radius;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-height: auto;

    &:hover {
      z-index: 4;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  .card-container {
    background-color: $card-bg;
    padding: 1rem 0.75rem;
    border-radius: $radius;
    position: relative;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    height: 100%;

    &.active-borders {
      border: 1px solid $primary;
    }

    .card-second-row {
      flex: 1;
      gap: 0.5rem;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .first-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0px;
        overflow: hidden;
      }
      .first-item-text {
        // width: min-content;
        // max-width: 20ch !important;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        margin-bottom: 0;
      }

      .second-item-text {
        font-size: 0.75rem;
        text-align: end;
      }
    }

    .buttons-container {
      transition: ease-in-out 150ms all;
      position: absolute;
      display: flex;
      right: 0.5rem;
      top: -1rem;
      gap: 0.5rem;
      z-index: 3;
    }
    .buttons-notification-container {
      transition: ease-in-out 150ms all;
      position: absolute;
      display: flex;
      right: unset;
      top: -1rem;
      gap: 0.5rem;
      z-index: 3;
      opacity: 1;
    }

    .thumbnail-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 120px;
    }
  }

  .details-container {
    width: calc(100% + 2px);
    display: flex;
    gap: 0.25rem;
    padding: 0.75rem;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    transition: ease-in-out 150ms all;
    left: -1px;
    right: 2px;
    top: 99%;
    border-radius: 0 0 $radius $radius;
    background-color: $card-bg-light;
    flex-direction: column;
    justify-content: space-between;

    .info-row {
      gap: 0.1rem;
      display: flex;
      justify-content: space-between;

      .info-item-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .force-opened-details {
    display: flex;
    gap: 0.25rem;
    padding: 0.75rem;
    border-radius: 0 0 $radius $radius;
    background-color: $card-bg-light;
    flex-direction: column;
    justify-content: space-between;

    .info-row {
      display: flex;
      justify-content: space-between;

      .info-item-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .remove-height-full {
    height: auto;
  }

  .clickable {
    cursor: pointer;
  }
}

.subHeader.card-container {
  display: inline-block;
  width: 100%;
}
