.form-card-container {
  max-width: 500px;
  width: 90%;
  margin-inline: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .ant-row.ant-form-item-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .ant-form-item {
    width: 100%;
  }

  .ant-col.ant-form-item-control {
    max-width: 300px;
  }
  .ant-btn.ant-btn-primary {
    float: right;
  }
  .submit-btn-container .ant-col.ant-form-item-control {
    max-width: none;
  }
}
