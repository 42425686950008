.region-add-container {
  .ant-input-group.ant-input-group-compact {
    display: flex;
  }
}
.regionTable {
  .ant-list-item-meta-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .ant-list-item-meta-description {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
