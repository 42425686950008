.versionFilesTable {
  margin-top: 32px;
  .ant-tabs {
    overflow: unset;
  }
  .filter-row {
    margin: 10px;
  }
  .ant-upload-list-item-info {
    max-width: 180px;
    min-width: 150px;
  }
}
