.Loading__Wrapper {
  min-height: 100px;
  min-width: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
}

.Loading__Wrapper #Fill-3,
.Loading__Wrapper #Fill-4,
.Loading__Wrapper #Fill-5 {
  animation: loadingDot 3s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  opacity: 0;
}

.Loading__Wrapper #Fill-3 {
  animation-delay: 0.3s;
}

.Loading__Wrapper #Fill-4 {
  animation-delay: 0.7s;
}

.Loading__Wrapper #Fill-5 {
  animation-delay: 0.5s;
}

@keyframes loadingDot {
  0% {
    opacity: 0;
  }
  38% {
    opacity: 1;
  }
  58% {
    opacity: 1;
  }
  88% {
    opacity: 0;
  }
  98% {
    opacity: 0;
  }
}
