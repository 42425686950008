@import '../../../Theme/variables';

.card-edit-info .card-edit-item {
  background-color: $card-bg;
  padding: 6px;
  border-radius: 8px;
  margin: 4px;
  transition: all 150ms ease-in-out;
  width: 54px;

  &:hover {
    background-color: $card-bg2;
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }

  &.no-permission:hover {
    background-color: $card-bg;
    cursor: not-allowed;
  }

  .card-name {
    font-size: 0.85rem;
    text-align: center;
  }

  .card-id {
    font-size: 0.75rem;
    text-align: center;
  }
}
