[class$='-USteps-container'] {
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

// [class$='-UStep-child'] {
//   height: 40px;
//   background-color: black;
// }

// [class$='-UStep-variant-error'] {
//   background-color: red;
// }
// [class$='-UStep-variant-success'] {
//   background-color: green;
// }
// [class$='-UStep-variant-warning'] {
//   background-color: yellow;
// }
.UD-UStep-child {
  transition-duration: 0.1s;
  height: 15px;
  display: inline-flex;
  background-color: black;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  cursor: default;
  color: white;
  &:hover {
    transform: scale(1.03);
    border-radius: 2px;
  }
}

.UD-UStep-variant-error {
  background-color: #ea2027;
}
.UD-UStep-variant-success {
  background-color: #009432;
}
.UD-UStep-variant-warning {
  background-color: #c4e538;
}
.ant-tooltip-inner {
  border-radius: 8px !important;
}
