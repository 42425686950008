.home-page-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  margin: -16px;
  height: 100%;

  .selector-header-row {
    gap: 0.5rem;

    .selector-title {
      margin: 0;
    }

    .selector-tag {
      word-wrap: break-word;
      white-space: normal;
      text-align: center;
    }
  }

  .group-brand-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .store-selector-container {
    max-width: 285px;
  }

  .homepage-entity-selector-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-height: calc(100vh - 120px);
  }

  .homepage-entity-selector {
    overflow-y: auto;
    padding: 0.85rem 0.75rem;
    gap: 0.2rem;
  }

  .brand-selector-container {
    max-height: calc(100vh - 290px);
  }

  .homepage-divider {
    height: auto;
  }

  .card-container {
    padding: 0.4rem;
  }

  h4.ant-typography {
    margin-bottom: 0;
  }

  .search-input {
    flex: 1;
  }

  .archive-toggle-btn {
    margin-left: 0;
  }

  .store-selector-row {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
  }
}
