.svg_wrapper {
  position: relative;
  .svg_container {
    transform-origin: top left;
    position: absolute;
    top: 0;
    left: 0;
    text {
      user-select: none;
      pointer-events: none;
    }
  }
  img {
    width: 100%;
  }
}
