.board-detail-container {
  .ant-badge-dot {
    z-index: 6;
  }

  .card-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  .board-card {
    .card-second-row {
      height: 100%;
      justify-content: space-around;
      align-items: center;
      .first-item-text {
        text-align: center;
      }
    }
  }

  .info-row {
    & > * {
      flex: 1;
    }
  }
}
