.modal-footer-wrapper {
  .ant-card-meta-detail {
    width: 100%;
  }

  .modal-footer-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
