.Data {
  .selectedFilters {
    .ant-select {
      min-width: 180px;
    }
    .ant-input {
      width: 180px;
    }
    .inputName {
      font-weight: 500;
    }
  }
  .card-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  }
  .card-second-row .first-item-text {
    width: 380px !important;
  }
}
