.groups-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .groups-wrapper .card-grid-container {
    grid-template-columns: repeat(auto-fit, 330px);
  }

  .ant-col.card-wrapper {
    max-width: 304px;
  }

  .version-file-card {
    max-width: 304px;
  }
}
