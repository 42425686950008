@import '../../Theme/palette';
@import '../../Theme/variables';

.store-products-form {
  .ant-col.ant-form-item-control {
    max-width: none;
  }

  .all-store-products-row {
    border-radius: 4px;
    padding: 6px;
    background-color: $concrete-50;
  }

  .store-product-row {
    border-radius: 4px;
    padding: 6px;
    &:hover {
      background-color: $concrete-100;
    }
  }
}
