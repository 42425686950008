.role-add-wrapper {
  max-width: 90vw;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .ant-card-meta-detail {
      width: 100%;
    }
  }

  .role-add-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ant-row.ant-form-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .ant-col.ant-form-item-control {
      max-width: 300px;
    }
    .ant-btn.ant-btn-primary {
      float: right;
    }
    .submit-btn-container .ant-col.ant-form-item-control {
      max-width: none;
    }
  }
}
