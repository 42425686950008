.BoardCollapse {
  .ant-collapse-header {
    padding: 8px 16px !important;
  }
}

.store-detail-wrapper {
  max-width: 800px;

  .coordinates-form-item {
    .ant-form-item-control {
      max-width: none;
    }
  }
}
