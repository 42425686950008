.network-configiration {
  max-width: none;

  .add-wifi-conf-button {
    width: 100%;
  }
  .col-form {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 15px;
  }
  .add-wifi-conf-button {
    display: flex;
    margin: auto;
    width: 24%;
    justify-content: center;
    align-items: center;
  }

  .wifi-conf-form-container {
    max-width: 21vw;
    margin: auto;
  }

  .large-add-button {
    height: 100%;
    width: 100%;

    &-container {
      border: none !important;
      padding: 0 !important;
      min-height: 398px;
    }

    .plus {
      font-size: 400%;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}
