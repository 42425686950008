#connect-cameras-page {
  .camera-name {
    position: absolute;
    border-radius: 2px;
    padding: 0 5px;
    top: 10px;
    left: 35px;
    background-color: rgba(255, 255, 255, 0.55);
    color: black;
    z-index: 1;
    font-size: 12px;
  }
  .draggable-overflow {
    width: 100%;
    overflow: auto;
    margin-top: 5px;
    .camera-draggable-wrapper {
      > * {
        width: 300px;
        min-height: 100px;
      }
    }
  }
}
