.board-count-info {
  display: flex;
  flex-direction: row;

  &-column {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .ant-tag {
      align-self: stretch;
      text-align: center;
      margin-right: 4px;
    }
  }
}
