@import '../../Theme/palette';
@import '../../Theme/variables';

#login-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 100vh;
  width: 100vw;
  background-color: $bg-white;

  #login-form-container {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 4rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }

  .login-form-forgot {
    float: right;
  }

  .login-form-button-container .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
}
