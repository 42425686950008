.add-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  gap: 0.5rem;

  .groups-link {
    margin-left: auto;
  }
}
