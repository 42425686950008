.homepage-map-container {
  position: relative;
  flex: 1;
}

.homepage-marker {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;

  &-label {
    width: max-content;
    background-color: rgba(255, 255, 255, 0.7);
    color: #000;
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.15rem 0.25rem;
    margin: 0;
    border-radius: 8px;
    z-index: 2;
  }
}
