.store-detail-container {
  .ant-badge-dot {
    z-index: 6;
  }

  .card-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  .store-card {
    .card-second-row {
      height: 100%;
      justify-content: space-between;
      align-items: center;
      .first-item-text {
        text-align: center;
      }
    }
  }
  .ant-badge {
    padding: 4px;
  }

  .buttons-row {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    margin-left: auto;
  }
}
