#camera-records-page {
  .tag-filter {
    width: 140px;
  }

  button.get-cam-records-btn {
    margin-left: 0;
  }

  .camera-records-summary {
    margin-bottom: 1.5rem;
  }
}
