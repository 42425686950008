.back-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  .anticon.anticon-arrow-left {
    line-height: 0;
  }
}
