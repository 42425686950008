.field-search-items {
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;

  .field-searches-header {
    display: flex;
    flex-direction: row;

    .title-header {
      margin: 0;
      flex: 1.04;
    }

    .keywords-header {
      margin: 0;
      flex: 3;
    }
  }

  .add-field-search-button {
    width: 100%;
  }
}
