.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 15ch;
  margin: 5px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 2px;
}
