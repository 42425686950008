@import '../../../../Theme/variables';

.chat-bubble {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid #f0f0f0;
  width: 40%;
  min-width: 300px;
  padding: 0.5rem;
  background-color: #fcfcfc;

  &.right-bubble {
    margin-left: auto;
    background-color: $primary;

    .chat-text {
      color: #fff;
    }

    .info-text {
      color: #d8d8d8;
    }
  }

  .chat-bubble-info {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    align-items: flex-end;
  }

  .chat-text {
    font-weight: 500;
    color: #171717;
  }

  .info-text {
    color: #4f4f4f;
  }
}
