.app-header {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  .header-title {
    color: #fff;
    font-size: 1.2rem;
    margin: 0;
  }

  .right-side-container {
    float: right;
  }

  .timezone-text {
    color: lightgray;
  }

  .user-actions {
    float: right;
    cursor: pointer;
  }
  .language {
    opacity: 0.7;
    .ant-avatar {
      border: 1px solid transparent;
    }
    .ant-avatar:hover {
      border-color: rgba(0, 0, 0, 0.4);
    }
    &.active {
      opacity: 0.9;
      .ant-avatar {
        border-color: black;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  .homepage-view-selector {
    background-color: #171717;

    &:hover {
      background-color: #171717;
    }

    .ant-segmented-item {
      color: #bdbdbd;
      transition: #202020 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-segmented-item-selected {
      color: #fff;
      background-color: #4f4f4f;
    }

    .ant-segmented-thumb {
      background-color: #4f4f4f;
    }
  }
}
