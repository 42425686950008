.camera-record-form {
  .camera-record-form-row {
    margin-block: 1.2rem;
  }

  .form-select-item {
    float: right;
    max-width: 250px;
    width: 100%;
  }
}
