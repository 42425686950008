@import './palette';

$bg-white: #f0f2f5;
$white: #ffffff;
$black: #131313;
$light-black: #4f4f4f;
$card-bg: #eaf6ff;
$card-bg2: #daeeff;
$card-bg-light: #fbfdff;
$card-bg-light2: #f4f9fd;
$primary: $belize-hole-700;
$scrollbar-thumb: $primary;
$scrollbar-bg: $silver-300;
