.custom-filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .ant-select.ant-select-single.ant-select-show-arrow {
    min-width: 120px;
  }
  .option-icon {
    padding-left: 6px;
  }
  .new-condition-container {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }
}

.inner-filter-container {
  .ant-select.ant-select-single.ant-select-show-arrow {
    min-width: 120px;
  }

  .new-condition-container {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }
}
