.camera-detail-container {
  .ant-badge-dot {
    z-index: 6;
  }

  .camera-image {
    display: none;
  }

  .card-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  }

  .camera-card {
    .card-second-row {
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      align-items: center;
      .first-item-text {
        text-align: center;
      }
    }
  }
}
