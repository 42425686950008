.svg-absolute-fill-polygon-add {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.45);
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  &:hover {
    background-color: rgba(90, 90, 90, 0.45);
  }
}
