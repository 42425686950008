@import '../../../../Theme/palette';
@import '../../../../Theme/variables';

.cluster-info {
  width: max-content;
  max-height: 300px;
  max-width: 300px;
  overflow-y: auto;
  border-radius: 0.5rem;
  padding: 0 0.25rem;

  .cluster-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
  }

  .cluster-label {
    color: gray;
    font-size: 0.75rem;
    margin: 0;
  }
}
