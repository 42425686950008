.active-file-change-details {
  .board-title {
    text-align: center;
    background-color: rgb(245, 245, 245);
    padding-block: 0.25rem;
  }

  .headers {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .file-types {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .column-header {
    font-weight: 600;
  }

  .file-type {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-seperator {
      flex: 1;
      text-align: center;
      font-weight: 700;
      font-size: 1.25rem;
    }

    &-card {
      flex: 4;
      min-height: 46px;
    }

    &-label {
      flex: 2;
      font-weight: 400;
    }
  }

  .update-button-container {
    display: flex;
    flex: 1;
  }

  .update-button {
    margin-left: auto;
    margin-top: 1rem;
  }
}
