.chat-sender {
  display: flex;
  position: sticky;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin-top: 1rem;

  &-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 0.5rem;
  }
}
