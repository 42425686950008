.field-searches-header {
  h4 {
    text-align: center;
    margin: 0;
  }

  .main-pannel-anchor {
    .ant-anchor {
      padding: 0;
      margin: 0;
    }

    .ant-anchor-link {
      padding: 0;
      margin: 0 0.5rem;
    }

    .ant-anchor-ink {
      display: none;
    }

    a {
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      font-size: 20px;
      line-height: 1.4;
      text-decoration: underline;

      &:hover {
        color: #40a9ff;
      }
    }
  }
}
