.BoardWifiForm {
  .ant-btn-icon-only {
    float: right;
    margin-bottom: 10px;
    width: 28px;
    height: 28px;
  }
  .deleteButton {
    .ant-btn {
      width: 31px;
      height: 31px;
    }
  }
}
.col-form-board {
  padding: 14px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
  margin-left: 2vw;
}
