.version-file-card {
  display: flex;
  max-width: 100%;

  .info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .info-col {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 0.15rem;
  }

  .card-second-row {
    flex-direction: column !important;
    gap: 0.15rem;
  }

  .first-item {
    flex: 1;
    align-self: flex-start;
    max-width: 100%;
  }

  &.active {
    background-color: #bdd8ee;
    border-color: #4079a5;
  }
}
