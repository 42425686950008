.field-searches-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: auto;
  align-items: center;
  max-width: 700px;

  .field-searches-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(250, 250, 250);
    border-radius: 0.25rem;
    padding: 2rem;
    gap: 1rem;

    @media (max-width: 768px) {
      border-radius: 0;
      background-color: inherit;
    }
  }
}
