.shape-context-menu {
  background: #fff;
  position: absolute;
  z-index: 7;
  box-shadow: 1px 0 11px -2px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 200px;
  color: #676869;
  &__Item {
    padding: 8px 5px;
    line-height: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    opacity: 0.7;
  }
  &__Item:not(:nth-child(1)) {
    border-top: 1px solid #f2f4f7;
  }
  &__Item:hover {
    background: #fafbfc;
    opacity: 1;
  }
}
