.brand-image-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;

  .brand-image-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }

  .upload-image-container {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 100%;
    height: 36px;
    margin: 0;
  }
}
