.sidebar-wrapper {
  overflow: auto;
  height: calc(100vh - 48px);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}
.sidebar-wrapper-test {
  background-color: #e99d54;
  .sidebar-container {
    background-color: #e99d54;
  }
  .ant-menu-inline .ant-menu-sub {
    background-color: #e18b38;
  }
  .ant-layout-sider-trigger {
    background-color: #e18b38;
  }
  .ant-menu-item-selected {
    background-color: #bf6b1aa6 !important;
  }
}
