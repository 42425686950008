.tickets-page-card {
  max-width: 1000px;
  margin-inline: auto;

  .ticket-page-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .tickets-content {
    display: flex;
    flex-direction: column;
  }
}
