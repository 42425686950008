.date-range-card {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
  background-color: rgb(247, 247, 247);
  padding: 0.5rem;
  border-radius: 0.5rem;

  .date-range-text {
    text-align: center;
  }

  .delete-button {
    position: absolute;
    right: -1rem;
    top: -1rem;
  }
}
