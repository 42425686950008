@import '../../Theme/palette';

.active-file-changed-button {
  background-color: $orange-400;

  &:hover {
    background-color: $orange-400;
  }

  &:focus {
    background-color: $orange-400;
  }
}
