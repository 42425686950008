.user-page-cards-container {
  .card-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .card-second-row {
    flex-direction: column;
  }

  .first-item {
    flex: 2 !important;
  }

  .second-item-text {
    flex: 1;
    text-wrap: wrap;
  }
}
