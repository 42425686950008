.error-alert-container {
  .error-message {
    text-align: left;
  }
}

.custom-ant-message {
  .anticon.anticon-close-circle {
    float: left;
    margin: 0.5rem, 0, 0, 0.5rem;
  }
}
