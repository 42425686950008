.role-edit-wrapper {
  .checkbox {
    width: 100%;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .role-edit-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .product-title {
      margin-block: 2em 0rem;
      width: 100%;
    }

    .category-title {
      color: rgb(110, 110, 110);
      width: 100%;
      margin-block: 1rem 0.5rem;
    }

    .ant-checkbox-wrapper.ant-checkbox-group-item {
      padding: 2px 4px;
    }

    .ant-row.ant-form-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .ant-btn.ant-btn-primary {
      float: right;
    }
    .submit-btn-container .ant-col.ant-form-item-control {
      max-width: none;
    }
  }
}
